import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import CaseStudiesDetailsContent from '../../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import banner_img from '../../assets/images/projects/project2.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'Africa is a significant producer of copper, with countries like Zambia and the Democratic Republic of Congo (DRC) having vast copper reserves. ',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'Copper mining plays a crucial role in the economies of many African nations. It contributes significantly to government revenue and job creation, supporting local communities.',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Copper is one of Africas top exports, generating substantial foreign exchange earnings. It is a key commodity in international trade.',
    },
    {
        id: 3,
        className: 'opensearch',
        label: 'Copper mining can have environmental impacts, including habitat disruption and pollution. Sustainable mining practices are essential to mitigate these effects.',
    },
];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"Zambian Copper Mine"}
                location={"Zambia"}
                technologies={"Mining, Copper, Analytics, Process Optimsation"}
                completed={"2023"}
                website={"https://neolytics.io"}
                type={"Mining"}
                title={"KPI model for underground Zambian copper mining operations"}
                description={"Introduced a comprehensive KPI model meticulously crafted for the underground copper mining operations in Zambia, encompassing everything from production tonnages to granular input metrics, such as truck fill factor and cycle times."}
                important_facts={""}
                long_description1={"We have developed a comprehensive KPI model tailored for underground copper mining operations in Zambia. This model covers a wide spectrum, ranging from measuring output tonnages to tracking essential input metrics such as truck fill factor and cycle times. The implementation of this model offers significant benefits, including improved operational efficiency, better resource management, and enhanced decision-making capabilities for optimizing mining processes in Zambia."}
                long_description2={""}
                results={"Published or shared upon request and in compliance with regulations."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails